@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap");
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #dbe8fe;
  font-family: "Inter", sans-serif;
}

.nav {
    background: rgb(75,147,255);
    background: linear-gradient(180deg, rgba(75,147,255,1) 0%, rgb(158, 194, 255) 100%);
    display: flex;
    height: 10vh;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
}

.navbrand {
    color: rgb(4, 0, 255);
    margin-left: 2vw;
    font-size: 1.5rem;
    font-weight: 600;
}

.login-account-btn {
    margin-right: 2vw;
}

.nav-right {
    margin-right: 2vw;
    font-size: 1.3rem
}

.mega {
    color: rgb(31, 45, 172)
}

.subhead {
    color:#dbe8fe;
    font-weight: 500;
}

.button {
  border-radius: 4px;
  border-color: none;
  border: none;
  background: rgb(31, 45, 172);
  color: #f9f9f9;
  box-shadow: none !important;
  padding: 0.5rem;
  font-weight: 500;
  letter-spacing: 2px;
  z-index: 3;
}


.btn-check:focus+.btn {
    box-shadow: none;
}

.modal-content {
     background-color: rgba(255, 255, 255, 0.835) !important;
     align-items: center;
     margin-bottom: 27vw;
}

.modal-50w {
    width: 15%;
}

.thinker {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(31, 45, 172)
}

.spin {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
}

.think-text {
    font-size: 1.5rem;
    font-weight: 500;
}

.aws-logo {
    max-width: 40%;
    margin-bottom: 0.5rem;
}

.icon {
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  z-index: 3;
}

.text-large {
  font-size: 25px;
}

.folder-text {
  font-size: 30px;
  margin-bottom: -5px;
  overflow: hidden;
}

.pass-form {
  margin-bottom: 1rem;
  width: 70%;
}

.link:hover {
  cursor:pointer;
  text-decoration:underline;
}

.formcard {
  width: 100%;
}

.input {
  font-weight: 400;
  outline: none !important;
  border-radius: 8px !important;
  height: 100%;
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;

  
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.06)
  );
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  border-left: 2px solid rgba(0, 0, 0, 0.5);
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  border-radius: 10px !important;
  z-index: 4;
  backdrop-filter: blur(40px);
}

.account-input {
  font-weight: 400;
  outline: none !important;
  height: 100%;
  width: 100%;
  padding: 6px;
  
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.06)
  );
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  background-clip: padding-box;
  border-radius: 10px !important;
  z-index: 4;
  backdrop-filter: blur(40px);
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {

  font-weight: 300 !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {

  font-weight: 300 !important;
}

.close-modal {
    position: absolute;
    display: inline;
    left: 94%;
    top: 1%;
}

.modal-body {
    padding: 0;
    padding-bottom: 2rem;
    width: 60%;
}

.checkmark {
    display: inline
}

.toggle-btns {
    display: flex;
    justify-content: space-around;
}

.build-ul {
    margin-top: 1rem;
    margin-left: 1rem;
}

.build-li {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.build-li:hover {
    text-decoration: underline;
}

.slot {
    min-width: 100px;
    min-height: 100px;
    border-radius: 10px;
    background-color: #5d80bdd3;
    border: #5d2b0ad3 2px solid;
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
}

.top {
    display: flex;
    flex-direction: column;
}

.large-icon {
    margin: 1rem;
    margin-bottom: 0.4rem;
    border-radius: 0.5em;
    border:rgba(0, 0, 0, 0.5) 2px solid;
}

.arrows {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 0.5rem;
}

.delete-item {
    position: absolute;
    align-self: flex-end;
}

.tray {
    margin-top: 3rem;
    display: inline-flex;
    justify-content: center;
    background-color: rgba(0, 36, 136, 0.719);
    padding: 1rem;
    border-radius: 1rem;
}

.champ-select {
    width: 50%;
    margin-top: 1rem;
    width: 20rem;
    height: 2rem;
}

.item-select {
    width: 50%;
    margin-top: 2rem;
    width: 20rem;
    height: 2rem;
}

.small-icon {
    max-width: 3rem;
}

.small-name {
    margin-left: 1rem;
    font-size: 1.5rem;
    text-justify: distribute;
}

.main-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right {
    transform: rotatey(180deg);
}

.champ-li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.new-build {
    text-decoration: underline;
    cursor: pointer;
}

.list-spinner {
    position: absolute;
    left: 10rem;
    top: 20rem;
    color: #3f5baa;
}

.build-title {
    margin-top: 1rem;
    font-size: 1.5em;
    font-weight: 500;
}